<!-- 首页 -->
<template>
    <div class="home">
		<div class="home-banner">
			<el-carousel height="740px" trigger="click">
				<el-carousel-item v-for="(item,index) in banner" :key="index">
					<div class="home-banner-item-bg"></div>
					<el-image
			           style="width: 100%; height: 740px"
			           :src="require('@/assets/images/'+item.url)"
			           fit="cover"></el-image>
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="home-model">
			<div class="home-model-main">
				<div class="home-model-brief">
					<div class="home-model-brief-left">
						
					</div>
					<div class="home-model-brief-right">
						
					</div>
					<div>
						<div class="home-model-brief-title">
							企业介绍
						</div>
						<div class="home-model-brief-text">
							辛荣信息坚持以技术研发、产品创新为核心。公司业务涵盖：APP定制开发、物联网IOT、数字孪生、政企信息化、智慧行业大数据等。
							我们提供定制开发解决方案，针对您的业务需求，资深产品经理在开发前期协助您梳理业务逻辑，明确技术方案与开发周期，项目交付上线后，也有专业团队为您提供技术运维和一对一的培训服务。
							同时我们有成熟的全行业小程序产品，覆盖社交电商、餐饮外卖、知识付费、同城O2O等20多个领域，具备会员体系、分销系统等50多项营销功能，系统每周迭代更新。集群化服务器全国CDN加速，确保最佳用户体验，多对一的售后服务全程保障您运营无忧。
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="home-model">
			<div class="home-model-title">
				<div class="home-model-title-max">
					服务范围
				</div>
				<div class="home-model-title-min">
					SERVICE SCOPE
				</div>
			</div>
			<div class="home-model-main">
				<el-row :gutter="20">
				  <el-col :span="6" v-for="(item,index) in service">
					  <div class="grid-content bg-purple">
						  <div class="grid-content-img">
						  	<el-image
						  	      style="width: 225px; height: 350px"
						  	      :src="require('@/assets/images/'+item.url)"
						  	      fit="cover"></el-image>
						  </div>
						  <div class="grid-content-info">
						  	<div class="grid-content-info-title">
						  		{{item.title}}
						  	</div>
							<div class="grid-content-info-text">
								{{item.text}}
							</div>
							<div class="grid-content-info-more">
								<div class="grid-content-info-more-btn">
									点击进入
								</div>
								
							</div>
						  </div>
					  </div>
				  </el-col>
				  
				</el-row>
			</div>
		</div>
		<div class="home-model" style="background-color: #000310;">
			<div class="home-model-title">
				<div class="home-model-title-max" style="color: #fff;">
					案例展示
				</div>
				<div class="home-model-title-min" style="color: #fff;">
					CASE PRESENTATION
				</div>
			</div>
			<div class="home-model-main">
				<div class="home-model-case">
					<div class="home-model-case-menu">
						<div class="home-model-case-menu-item" v-for="(item,index) in tab" :class="tabid == index?'active':''" @click="tabHandle(index)">
							{{item.name}}
						</div>
						
					</div>
					<div class="home-model-case-main">
						<div class="home-model-case-main-box">
							<el-row :gutter="24">
							  <el-col :span="8" v-for="(item, index) in tabBox" :key="index">
							    <el-card :body-style="{ padding: '0px' }">
									<div class="image-box">
										<el-image
										      style="width: 100%"
										      :src="require('@/assets/images/'+item.image)"
										      fit="cover"></el-image>
									</div>
									<div class="case-name">
										<span>{{item.name}}</span>
									</div>
							    </el-card>
							  </el-col>
							</el-row>
						</div>
						
					</div>
				</div>
			</div>
		</div>
		<div class="home-model">
			<div class="home-model-title">
				<div class="home-model-title-max">
					项目流程
				</div>
				<div class="home-model-title-min">
					PROJECT PROCESS
				</div>
			</div>
			<div class="home-model-main">
				<el-image
				      style="width: 100%"
				      :src="require('@/assets/images/process.png')"
				      fit="cover"></el-image>
			</div>
		</div>
		<div class="home-model" style="background-color: rgba(0,0,0,.4);">
			<div class="home-model-title">
				<div class="home-model-title-max" style="color: #fff;">
					联系我们
				</div>
				<div class="home-model-title-min" style="color: #fff;">
					CONTACT US
				</div>
			</div>
			<div class="home-model-main">
				<div class="home-model-contact-us">
					<div class="home-model-contact-us-info">
						<div class="home-model-contact-us-info-item">
							联系电话：18934724847  黄先生
						</div>
						<div class="home-model-contact-us-info-item">
							公司地址：广西南宁市良庆区良庆大道37号五栋2406
						</div>
						<div class="home-model-contact-us-info-item">
							邮箱地址：baiposezhuangchun@qq.com
						</div>
					</div>
					<div class="home-model-contact-us-map">
						<baidu-map class="bm-view" :center="center" :zoom="zoom" :scroll-wheel-zoom="true">
							<bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
							<bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
							<bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
						</baidu-map>
					</div>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
			banner:[{
				url:'banner01.webp'
			},
			{
				url:'banner02.jpg'
			},
			{
				url:'banner03.jpg'
			},
			{
				url:'banner04.webp'
			}],
			service:[{
				url:'service01.png',
				title:'小程序开发',
				text:'按需定制微信小程序、支付宝小程序、抖音小程序等，多行业场景适用。'
			},{
				url:'service02.png',
				title:'APP开发',
				text:'IOS/Android/HarmonyOS开发，满足直播/电商/教育/餐饮等行业领域的业务需求。'
			},{
				url:'service03.png',
				title:'WEB开发',
				text:'品牌官网、电商、企业营销、官方门户、信息门户、集团站群等网站量身定制。'
			},{
				url:'service04.png',
				title:'系统开发',
				text:'打造统一管理、多端协作、无纸化的分布式可视化OA、CRM、ERP信息管理系统。'
			}],
			tabid:0,
			tab:[{
				name:'小程序开发'
			},
			{
				name:'APP开发'
			},
			{
				name:'网站建设'
			}],
			tabBox:[],
			tabList:[{
				name:'小程序开发',
				children:[{
					name:'社团平台',
					content:'',
					image:'caoa_wx.jpg'
				},{
					name:'蜂蜜家居',
					content:'',
					image:'beejia_wx.jpg'
				},{
					name:'嵘华科技',
					content:'',
					image:'rhkj.jpg'
				},{
					name:'鲸视觉',
					content:'',
					image:'jshijue.jpg'
				}]
			},{
				name:'APP',
				children:[{
					name:'蜂蜜家居',
					content:'',
					image:'beejia_app.jpg'
				}]
			},{
				name:'网站',
				children:[{
					name:'caoa管理系统',
					content:'',
					image:'caoa.png'
				},{
					name:'soms管理系统',
					content:'',
					image:'soms.png'
				}]
			}],
			center: {lng: 108.377341, lat:22.729416},
			zoom: 15,
		};
    },
    mounted() { 
		this.tabBox = this.tabList[0].children
	},
	methods:{
		tabHandle(index){
			this.tabid = index;
			this.tabBox = this.tabList[index].children
		}
	}
};
</script>
<style lang="less" scoped>
	.home{
		width: 100%;
		.home-banner{
			width: 100%;
			height: 740px;
			.home-banner-item-bg{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 740px;
				z-index: 1;
				background: rgba(0, 0, 0, .3);
			}
		}
		
		.home-model{
			padding: 50px 0 50px;
			.home-model-title{
				margin-bottom: 50px;
				.home-model-title-max{
					font-family: "PingFang SC";
					font-weight: bold;
					font-size: 50px;
					line-height: 70px;
					text-align: center;
					color: #333;
				}
				.home-model-title-min{
					font-family: "Helvetica Neue";
					font-weight: bold;
					font-size: 28px;
					line-height: 46px;
					text-align: center;
					color: #333;
				}
			}
			.home-model-main{
				width: 1200px;
				margin: auto;
				.home-model-contact-us{
					width: 100%;
					height: 400px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					.home-model-contact-us-info{
						flex: 1;
						.home-model-contact-us-info-item{
							line-height: 32px;
							color: #fff;
						}
					}
					.home-model-contact-us-map{
						flex: 1.5;
						.bm-view {
							width: 100%;
							height: 300px;
						}
					}
				}
				.home-model-case{
					display: flex;
					justify-content: space-between;
					align-items: center;
					.home-model-case-menu{
						flex: 1;
						background-color: rgba(151, 151, 151, 0.1);
						padding: 25px 25px 25px 0;
						min-height: 440px;
						.home-model-case-menu-item{
							color: #fff;
							height: 42px;
							display: flex;
							flex-wrap: wrap;
							justify-content: flex-start;
							align-items: center;
							margin-bottom: 40px;
							padding-left: 30px;
							cursor: pointer;
							transition: all 0.3s linear;
							position: relative;
							left: -10px;
						}
						.home-model-case-menu-item.active,.home-model-case-menu-item:hover {
							background: -webkit-gradient(linear, left top, right top, from(#005AFF), to(#00DCD2));
							background: -webkit-linear-gradient(left, #005AFF 0%, #00DCD2 100%);
							background: -o-linear-gradient(left, #005AFF 0%, #00DCD2 100%);
							background: linear-gradient(to right, #005AFF 0%, #00DCD2 100%);
						}
						.home-model-case-menu-item.active:after,.home-model-case-menu-item:hover:after {
						    content: '';
						    width: 10px;
						    height: 10px;
						    background: url("~@/assets/images/menu_bg.png") center center no-repeat;
						    background-size: 100% auto;
						    position: absolute;
						    left: 0;
						    bottom: -10px;
						}
					}
					.home-model-case-main{
						flex: 4;
						height: 440px;
						.home-model-case-main-box{
							width: 90%;
							height: 100%;
							margin: auto;
							.el-card{
								background-color: transparent;
								border: 0;
								.image-box{
									width: 100%;
									max-height: 170px;
									overflow: hidden;
									.image{
										width: 100%;
									}
								}
								.case-name{
									padding: 14px;
									text-align: center;
									color: #fff;
								}
							}
						}
					}
				}
				.home-model-brief{
					height: 300px;
					.home-model-brief-left,.home-model-brief-right{
						width: 350px;
						height: 300px;
						background-image: url('~@/assets/images/brief.png');
						background-size: 540px 300px;
						background-repeat: no-repeat;
					}
					.home-model-brief-left{
						float: left;
						shape-outside: polygon(0% 0%,0% 220%,100% 0%);
						clip-path: polygon(0% 0%,0% 180%,100% 0%);
						margin-right: 80px;
						background-position: left top;
					}
					.home-model-brief-right{
						float: right;
						shape-outside: polygon(100% -120%,0 100%,100% 100%);
						clip-path: polygon(100% -80%,0 100%,100% 100%);
						margin-left: 80px;
						background-position: right top;
					}
					.home-model-brief-title{
						width: 1200px;
						height: 40px;
						color: #111F2C;
						font-size: 36px;
						text-align: center;
						font-family: "PingFangBold";
						margin-bottom: 20px;
					}
					.home-model-brief-text{
						margin-top: 5px;
						font-weight: 400;
						font-size: 16px;
						color: #111F2C;
						line-height: 24px;
						text-align: justify;
						-webkit-line-clamp: 12;
					}
				}
				.grid-content{
					width: 225px;
					height: 350px;
					border-radius: 6px;
					overflow: hidden;
					margin: auto;
					position: relative;
					.grid-content-info{
						position: absolute;
						top: 0;
						left: 0;
						z-index: 1;
						width: 100%;
						height: 100%;
						padding: 20px 0;
						box-sizing: border-box;
						background-color: rgba(0, 0, 0, .4);
						.grid-content-info-title{
							text-align: center;
							color: #fff;
							line-height: 310px;
						}
						.grid-content-info-text{
							width: 100%;
							padding:20px 30px;
							box-sizing: border-box;
							color: #fff;
							text-align: center;
							white-space: normal;
							word-break: break-word;
							margin-bottom: 20px;
							display: none;
						}
						.grid-content-info-more{
							width: 100%;
							height: 60px;
							display: flex;
							justify-content: center;
							align-items: center;
							display: none;
							.grid-content-info-more-btn{
								font-size: 14px;
								padding: 5px 10px;
								color: #fff;
								border: 1px solid #fff;
								cursor: pointer;
							}
							.grid-content-info-more-btn:hover{
								background-color: #fff;
								color: #333;
							}
						}
					}
				}
				.grid-content:hover{
					.grid-content-info-title{
						line-height: 34px;
						margin-bottom: 20px;
					}
					.grid-content-info-text{
						display: block;
					}
					.grid-content-info-more{
						display: flex;
					}
				}
			}
			
		}
	}
</style>